/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2020 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

import $ from 'jquery';
import BaseModule from './BaseModule';
import {Toast} from 'bootstrap';

/**
 * Project module. All modules must have an async function called
 * {@link Project.init init()}. This function will be called on document ready.
 */
class Project extends BaseModule {
  topNavVisible = true;

  /**
   * This function will be called on document ready.
   */
  async init() {
    this._copyToClicpboard();
    // me._hideShowNavOnScrolling();
    this._closeNavbar();
    this._scrollTo();

    $(window).on('scroll', () => this._handleNavigationBar());
    this._handleNavigationBar();
  }

  async _handleNavigationBar() {
    const $pageHeaderClass = $('.page-header');
    if (window.pageYOffset > $pageHeaderClass.height()) {
      $pageHeaderClass.addClass('fixed-top-home');
      // $pageHeaderClass.addClass('sticky-top');
      // $pageHeaderClass.removeClass('fixed-top');
    } else {
      $pageHeaderClass.removeClass('fixed-top-home');
      // $pageHeaderClass.removeClass('sticky-top');
      // $pageHeaderClass.addClass('fixed-top');
    }
  }



  //
  // scroll to
  // -----------------------------------------------
  async _scrollTo() {
    $(document).on('click', 'a.scroll-to-btn', (event) => {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $($(event.currentTarget).data('scrollTo')).offset().top
      }, 500);
    });
  }

  //
  // Copy to clipboard
  // -----------------------------------------------
  async _copyToClicpboard() {
    $(document).on('click', '.copy-to-clipboard', (event) => {
      event.preventDefault();

      // const inputc = document.body.append(document.createElement('input'));
      let $temporary = $('<input>');
      $('body').append($temporary);
      $temporary.val($(event.currentTarget).data('uri')).trigger('select');
      document.execCommand('copy');
      $temporary.remove();

      let toastElementList;
      toastElementList = $(event.currentTarget).data('pos').length > 0 ? Array.prototype.slice.call(document.querySelectorAll('.toast-' + $(event.currentTarget).data('pos'))) : Array.prototype.slice.call(document.querySelectorAll('.toast-default'));

      toastElementList.map(function (toastElement) {
        return new Toast(toastElement, {
          delay: 2500
        }).show();
      });
    });
  }

  async _closeNavbar() {
    $(document).on('click', '.content-wrapper', () => {
      $('.offcanvas-collapse.open').removeClass('open');
    });

    $(document).on(
      'keydown',
      function (event) {
        if (event.key === 'Escape') {
          $('.offcanvas-collapse.open').removeClass('open');
        }
      });
  }

  //
  // some scrolling function
  // -----------------------------------------------
  async _hideShowNavOnScrolling() {
    $(window).on("scroll", (event) => {
      const $navbarExpandClass = $('.navbar-expand');
      const $navbarBgClass = $('.navbar-bg');
      const $navbarTogglerId = $('#mainNavbarToggler');

      if ($(window).width() <= 1024) {
        $navbarExpandClass.hide();
        $navbarBgClass.hide();
        $('.navbar-collapse').show();

      } else {
        if (this.topNavVisible === true && event.currentTarget.scrollY > 220) {
          $navbarExpandClass.fadeOut();
          $navbarBgClass.fadeOut();
          $navbarTogglerId.fadeIn();
          this.topNavVisible = false;
        } else if (this.topNavVisible === false && event.currentTarget.scrollY <= 220) {
          $navbarExpandClass.fadeIn();
          $navbarBgClass.fadeIn();
          $navbarTogglerId.fadeOut();
          this.topNavVisible = true;
        }
      }
    });
  }
}

export default Project;
