/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2021 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

import BaseModule from './BaseModule';
import $ from 'jquery';
import { Modal } from 'bootstrap';

/**
 * CareerModal module. All modules must have an async function called
 * {@link CareerModal.init init()}. This function will be called on document ready.
 */
class CareerModal extends BaseModule {
  /**
   * This function will be called on document ready.
   */
  async init() {
    if (!document.querySelector('.card-link')) {
      return;
    }

    const careerModalElement = document.querySelector('#careerModal');

    careerModalElement.addEventListener('show.bs.modal', function (event) {
      const $modal = $(event.currentTarget);
      const $button = $(event.relatedTarget);
      const url = $button.data('modalUrl');
      const id = $button.data('modalId');

      $.ajax(`${url}/${id}`)
        .done(function (resp) {
          const data = resp.data;
          $modal.find('.modal-body .career__title').html(data.Title);
          $modal.find('.modal-body .career__employment').html(data.Employment);
          // $modal.find('.modal-body .career__html').html(data.HTML);
        });
    });

    $(document).on('click', '#careerModal button[name="action_doSubmitForm"]', (event) => {
      event.preventDefault();

      let error = 0;
      const $button = $(event.currentTarget);
      const $form = $(event.currentTarget).closest('form');
      $form.find('input, select, textarea').each(function () {
        const $input = $(this);

        if ($input.attr('required') && !$input.val()) {
          error++;
        }
      });

      if (!error) {
        const $url = $form.attr('action');
        let data = $form.serialize();
        data += `&${$button.attr('name')}=${$button.val()}`;

        $.ajax({
          type: 'POST',
          url: $url,
          data: data,
          dataType: 'json',
          success: () => {
            const modalElement = document.querySelector('#careerModal');
            Modal.getInstance(modalElement).hide();
          },
        });
      }
      else {
        // console.log('something went wrong ...');
      }
    });
  }
}

export default CareerModal;
