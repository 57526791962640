/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2021 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

import BaseModule from './BaseModule';
import $ from 'jquery';

/**
 * Carousel module. All modules must have an async function called
 * {@link Carousel.init init()}. This function will be called on document ready.
 */
class Carousel extends BaseModule {
  /**
   * This function will be called on document ready.
   */
  async init() {
    if (!document.querySelector('.carousel-wrapper')) {
      return;
    }

    setTimeout(() => {
      this._handleCarouselHeight();
    }, 500);


    window.scrollTo(0,1);

    $(window).on('scroll', () => {
      this._handleCarouselHeight();
    });

    $(window).on('resize', () => {
      this._handleCarouselHeight();
    });

    $(document).on('click', '.carousel-button', (event) => {
      const scrollItemWidth = $('.carousel-scroll .scroll-item').width() + 32;
      const $carouselScrollClass = $('.carousel-scroll');
      const scrollPositionCurrent = $carouselScrollClass.scrollLeft();
      const slideNumber = scrollPositionCurrent / scrollItemWidth;
      const scrollPosition = (Math.round(slideNumber) * scrollItemWidth);
      const $carouselButton = $('.carousel-button button.left');

      if ($(event.target).hasClass('right') || event.target.parentNode.className === 'right') {
        this._hasClassRight($carouselScrollClass, $carouselButton, scrollPosition, scrollItemWidth);
      }
      if ($(event.target).hasClass('left') || event.target.parentNode.className === 'left') {
        this._hasClassLeft($carouselScrollClass, $carouselButton, scrollPosition, scrollItemWidth);
      }
    });
  }

  async _handleCarouselHeight() {
    let carouselHeight = $('div.carousel-wrapper').height();
    const $carouselConstContainer = $('div[data-equal-height="carousel-scroll"]');
    $carouselConstContainer.height(carouselHeight);

    let windowWidth = $(window).width();
    let containerWidth = $('.carousel-container').width();
    if (!containerWidth) {
      containerWidth = $('.container').width();
    }
    let marginLeft = (windowWidth - containerWidth) / 2;
    let carouselWidth = windowWidth - marginLeft;
    $carouselConstContainer.width(carouselWidth).css('left', marginLeft);

  }

  async _hasClassRight($carouselScrollClass, $carouselButton, scrollPosition, scrollItemWidth) {
    $carouselScrollClass.animate({ scrollLeft: scrollPosition + scrollItemWidth }, 800, function() {
      if ($carouselScrollClass.scrollLeft() > 0) {
        $carouselButton.removeClass('disabled');
      }
    });
  }

  async _hasClassLeft($carouselScrollClass, $carouselButton, scrollPosition, scrollItemWidth) {
    $carouselScrollClass.animate({ scrollLeft: scrollPosition - scrollItemWidth }, 800, () => {
      if ($carouselScrollClass.scrollLeft() === 0) {
        $carouselButton.addClass('disabled');
      } else {
        $carouselButton.removeClass('disabled');
      }
    });
  }
}

export default Carousel;
