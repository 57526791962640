/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2021 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

import BaseModule from './BaseModule';
import $ from 'jquery';

/**
 * Project module. All modules must have an async function called
 * {@link Project.init init()}. This function will be called on document ready.
 */
class FilterTag extends BaseModule {
  activeFilterValue = 'tag-all';
  activeURLSegment = undefined;

  /**
   * This function will be called on document ready.
   */
  async init() {
    this._filterTagSelect();
  }

  async _filterTagSelect() {
    if (localStorage.getItem('activeFilterValue')) {
      this.activeFilterValue = localStorage.getItem('activeFilterValue');
    }
    if (localStorage.getItem('activeURLSegment')) {
      this.activeURLSegment = localStorage.getItem('activeURLSegment');
    }

    const $all = $(`[data-value="tag-all"]`);
    const $pill = $(`[data-value="${this.activeFilterValue}"]`);

    let $active = $all;
    if ($pill && $pill.data('urlsegment') == this.activeURLSegment) {
      $active = $pill;
    }

    this.toggleFilter($active);

    $(document).on('click', '.filter-tag-list a', (event) => {
      localStorage.setItem('activeFilterValue', $(event.currentTarget).data('value'));
      localStorage.setItem('activeURLSegment', $(event.currentTarget).data('urlsegment'));
      this.toggleFilter($(event.currentTarget));
    });
  }

  toggleFilter($currentTarget) {
    $('.filter-tag-list a').removeClass('active');
    $currentTarget.addClass('active');
    if ($currentTarget.data('value') === 'tag-all') {
      $('.filter-content-list').fadeIn();
    } else {
      $.each($('.filter-content-list'), (index, element) => {
        if ($(element).data('filter') !== $currentTarget.data('value')) {
          $(element).fadeOut(200);
        } else {
          $(element).fadeIn(200);
        }
      });
    }
  }
}

export default FilterTag;
