/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2020 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

/**
 * !!! Do not include if you don't use them !!!
 */
import '@popperjs/core';
import 'bootstrap';
import 'bootstrap-select';
import 'parsleyjs';

// Parsley
import './lib/parsley';
// lib imports
import * as Modules from './modules';

/**
 * Load all modules by creating an instance and call init function.
 */
document.addEventListener(
  'DOMContentLoaded',
  async () => {
    await new Promise((resolve) => setTimeout(resolve, 1));

    await Promise.all(
      Object.keys(Modules).map(async (moduleKey) =>
        // eslint-disable-next-line import/namespace
        Modules[moduleKey].create().init()
      )
    );
  },
  false
);
