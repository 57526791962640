import * as Parsley from 'parsleyjs';
import ParsleyDe from '../../lang/parsley.de';

const ParsleyConfig = {
  async addLanguage(langShort, language, setLocale = true) {
    Parsley
      .addMessages(langShort, language)
      .setLocale(setLocale ? langShort : '');

    return ParsleyConfig;
  },

  async addValidator(validatorName, validator) {
    Parsley.addValidator(validatorName, validator);

    return ParsleyConfig;
  },
};

// Add german language to parsley
ParsleyConfig.addLanguage('de', ParsleyDe);
