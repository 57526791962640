/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package
 * @copyright 2020 idsolutions gmbh
 * @see       https://code.idsolutions.at/
 */

/**
 * Modules stuff
 */
export { default as Project } from './modules/Project';
// export { default as Offcanvas } from './modules/Offcanvas';
export { default as Carousel } from './modules/Carousel';
export { default as CarouselBootstrap } from './modules/CarouselBootstrap';
export { default as FormModal } from './modules/FormModal';
export { default as CareerModal } from './modules/CareerModal';
export { default as FilterTag } from './modules/FilterTag';
